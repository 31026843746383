import React, { useState, useEffect } from "react";
import StudCompleteIcon from "../assets/images/icons/complete__stud.svg";
import SettingIcon from "../assets/images/icons/setting__icon.svg";
import { useSelector, useDispatch } from "react-redux";
import {
	handleActiveTab,
	handleSwapTabs,
} from "../actions/header/headerActions";
import SelectYourDiamond from "../component/ApplicationPreview/SelectYourDiamond";
import SelectYourDiamondService from "../services/select-your-diamond.service";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Header = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const currentTab = useSelector((state) => state.headerReducer);
	const search = useLocation().search;
	const tabName = new URLSearchParams(search).get("tabName");
	const CurrentTab = new URLSearchParams(search).get("tabName");
	const paramdealerId = new URLSearchParams(search).get("DealerID");
	const paramdealerId2 = new URLSearchParams(search).get("DealerLink");
	const diamondId = new URLSearchParams(search).get("diamondId");
	const paramDetailLinkURL = new URLSearchParams(search).get(
		"paramDetailLinkURL"
	);
	const paramCut = new URLSearchParams(search).get("paramCut");
	const paramSize = new URLSearchParams(search).get("size");
	const paramdealerId1 = new URLSearchParams(search).get("dealerID");
	const pairId = new URLSearchParams(search).get("pairId");
	const totalPrice = new URLSearchParams(search).get("totalPrice");
	const MountEdit = new URLSearchParams(search).get("mountEdit");
	const DiamondViewEdit = new URLSearchParams(search).get("diamondViewEdit");
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState("");
	const [tabDetails, setTabDetails] = useState([]);
	const [tabdiamondEarning, setTabDiamondEarning] = useState(false);
	const [choosetabdiamondEarning, setChooseTabDiamondEarning] = useState(false);
	const previewcolor = useSelector((state) => state.previewColorReducer);
	const [toggle, setToggle] = useState(true);
	const [varColorObj, setVarColorObj] = useState({
		callToActionColor: "",
		hoverColor: "",
		columnHeaderAccentColor: "",
		linkColor: "",
	});

	const chooseYourEarning = useSelector(
		(state) => state.handleChooseYourEarningStudSetting
	);

	useEffect(() => {
		if (paramdealerId || paramdealerId2) {
			handleGetstudBuilderOptiondetails(
				paramdealerId ? paramdealerId : paramdealerId2
			);
		}
	}, []);

	const initialOptionsData = {
		studbuilderLinkURL: "",
		internalUseLink: true,
		showOnlyDiamondSearch: false,
		showDealerDiamondsSKU: true,
		showScheduleViewing: true,
		showAddress: true,
		showCallForPrice: false,
		applyGstTax: true,
		gstTaxValue: "",
		showPinterestShare: true,
		showTwitterShare: true,
		showFacebookShare: true,
		showFacebookLike: true,
		showAddToCartButton: true,
		ShoppingCartURL: "",
		showViewCartButton: true,
		ShowInstagramPlus: true,
	};
	const [myOptionsData, setMyOptionsData] = useState(initialOptionsData);

	const handleGetstudBuilderOptiondetails = (id) => {
		let inputData = {
			dealerID: id,
		};

		try {
			SelectYourDiamondService.GetstudBuilderOptiondetails(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData[0];
					if (message == "Success") {
						if (responseData) {
							if (response.data.responseData.length > 0) {
								setMyOptionsData((prevState) => ({
									...prevState,
									studbuilderLinkURL: responseData.studBuilderURL,
									internalUseLink: responseData.internalLinkSB,
									showOnlyDiamondSearch: responseData.onlyDiamondSearch,
									showDealerDiamondsSKU: responseData.stud_ShowSKU,
									showScheduleViewing: responseData.showScheduleView,
									showAddress: responseData.showAddresses,
									showCallForPrice: responseData.callForPricestud,
									applyGstTax: responseData.showGstPrice,
									gstTaxValue: responseData.taxRate,
									showPinterestShare: responseData.showPinterestShare,
									showTwitterShare: responseData.showTwitterShare,
									showFacebookShare: responseData.showFacebookShare,
									showFacebookLike: responseData.showFacebookLike,
									showAddToCartButton: responseData.showAddToCartButtonStud,
									ShoppingCartURL: responseData.diamondstudshoppingurl,
									showViewCartButton: responseData.showViewCartButtonStud,
									ShowInstagramPlus: responseData.showInstagramShare,
								}));
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
					setMyOptionsData((prevState) => ({
						showOnlyDiamondSearch: false,
					}));
				});
		} catch (error) {
			console.log(error);
			setMyOptionsData((prevState) => ({
				showOnlyDiamondSearch: false,
			}));
		}
	};

	useEffect(() => {
		if (chooseYourEarning) {
			if (chooseYourEarning.isViewEarningPageDetails) {
				setTabDiamondEarning(true);
			} else {
				setTabDiamondEarning(false);
			}
			if (chooseYourEarning.isViewChooseMountingPage) {
				setChooseTabDiamondEarning(true);
			} else {
				setChooseTabDiamondEarning(false);
			}
		}
	}, [chooseYourEarning]);

	const handleTabClick = (tab, index) => {
		if (choosetabdiamondEarning) {
			let tabDetailsArray = tabDetails;
			if (index == tabDetailsArray.length - 1) {
				let temp = tabDetailsArray[0];
				tabDetailsArray[0] = tabDetailsArray[1];
				tabDetailsArray[1] = temp;
				dispatch(handleSwapTabs(tabDetailsArray));
				dispatch(handleActiveTab(tab));
			}

			if (tab == "completeYourRing") {
				if (currentTab) {
					if (currentTab.isSettingViewEdit && currentTab.isDiamondViewEdit) {
						dispatch(handleActiveTab(tab));
					}
				}
			}
			dispatch(handleActiveTab(tab));
		}
	};

	useEffect(() => {
		if (previewcolor) {
			if (previewcolor.buttonColor) {
				setVarColorObj({
					...varColorObj,
					callToActionColor: previewcolor.buttonColor.callToActionColor,
					hoverColor: previewcolor.buttonColor.hoverColor,
					columnHeaderAccentColor:
						previewcolor.buttonColor.columnHeaderAccentColor,
					linkColor: previewcolor.buttonColor.linkColor,
				});
				setToggle(previewcolor.toogle);
			}
		}
	}, [previewcolor.toogle, toggle]);

	useEffect(() => {
		if (currentTab) {
			if (CurrentTab) {
				setActiveTab(CurrentTab);
			} else {
				setActiveTab(currentTab.activeTab);
			}
			if (currentTab.tabDetails) {
				setTabDetails(currentTab.tabDetails);
			}
		}
	}, [currentTab, currentTab.isSettingViewEdit]);

	const handleViewDiamond = () => {
		navigate(
			"/viewDiamondDetails" +
				`?isSocialPage=True&DealerID=${paramdealerId}&paramDetailLinkURL=${paramDetailLinkURL}&pageNo=1&isAllowed=True&paramCut=${paramCut}&diamondId=${diamondId}&isTab=false&tabName=selectYourDiamond`
		);
	};

	const handleEditDiamond = () => {
		navigate(
			"/selectYourDiamond" +
				`?DealerLink=${paramdealerId}&tabName=selectYourDiamond&isTab=True&isChange=True`
		);
	};

	const handleEditTabChooseYourMounting = () => {
		navigate(
			"/chooseYourSetting" +
				`?isSocialPage=True&DealerID=${paramdealerId}&dealerID=${paramdealerId1}&pageNo=1&isAllowed=True&paramCut=${paramCut}&size=${paramSize}&pairId=${pairId}&totalPrice=${totalPrice}&isTab=false&tabName=chooseyourmounting&diamondViewEdit=True`
		);
	};

	return (
		<>
			<div class="bcontainer" id="topHeader">
				<div class="breadcrumb">
					<>
						{tabDetails &&
							tabDetails.map((tab, index) => {
								return (
									<li
										className={tabName == "selectYourDiamond" && "active"}
										onClick={() => handleTabClick("selectYourDiamond")}>
										<div className="left__block">
											<span className="circle">1</span>
											<div class="breadcrumb__content">
												<p class="mb-0 text-left">
													{t("Select Your")} <br />{" "}
													<b className="subheading"> {t("Diamond")}</b>
												</p>
											</div>
										</div>

										<div className="right__block">
											{DiamondViewEdit == "True" ? (
												<>
													{" "}
													<div className="mr-1">
														<span className="mr-2" onClick={handleViewDiamond}>
															{t("View")}
														</span>
														<span>|</span>
														<span
															className="ml-2 mr-1"
															onClick={handleEditDiamond}>
															{t("Edit")}
														</span>
													</div>
												</>
											) : null}

											<img src={tab.imgSrc} />
										</div>
									</li>
								);
							})}
						{!myOptionsData.showOnlyDiamondSearch ? (
							choosetabdiamondEarning ? (
								<li className={tabName == "chooseyourmounting" && "active"}>
									<div className="left__block">
										<span className="circle">2</span>
										<div class="breadcrumb__content">
											<p class="mb-0 text-left">
												{t("Choose Your")} <br />{" "}
												<b className="subheading">{t("Mounting")}</b>
											</p>
										</div>
									</div>

									<div className="right__block">
										{MountEdit == "True" ? (
											<>
												{" "}
												<div className="mr-2">
													<span onClick={handleEditTabChooseYourMounting}>
														{t("Edit")}
													</span>
												</div>
											</>
										) : null}

										<img src={StudCompleteIcon} />
									</div>
								</li>
							) : (
								<li className={tabName == "chooseyourmounting" && "active"}>
									<div className="left__block">
										<span className="circle">2</span>
										<div class="breadcrumb__content">
											<p class="mb-0 text-left">
												{t("Choose Your")} <br />{" "}
												<b className="subheading">{t("Mounting")}</b>
											</p>
										</div>
									</div>
									<div className="right__block">
										<img src={StudCompleteIcon} />
									</div>
								</li>
							)
						) : null}
						{!myOptionsData.showOnlyDiamondSearch ? (
							tabdiamondEarning ? (
								<li className={tabName == "completeyourring" && "active"}>
									<div className="left__block">
										<span className="circle">3</span>
										<div class="breadcrumb__content">
											<p class="mb-0">
												{t("Complete Your")} <br />{" "}
												<b className="subheading">{t("Earrings")}</b>
											</p>
										</div>
									</div>
									<div className="right__block">
										<img src={StudCompleteIcon} />
									</div>
								</li>
							) : (
								<li className={tabName == "completeyourring" && "active"}>
									<div className="left__block">
										<span className="circle">3</span>
										<div class="breadcrumb__content">
											<p class="mb-0 text-left">
												{t("Complete Your")} <br />{" "}
												<b className="subheading">{t("Earrings")}</b>
											</p>
										</div>
									</div>
									<div className="right__block">
										<img src={StudCompleteIcon} />
									</div>
								</li>
							)
						) : null}
					</>
				</div>
			</div>
			{activeTab == "selectYourDiamond" && <SelectYourDiamond />}
		</>
	);
};

export default Header;
