import React, { useState, useEffect, useLayoutEffect } from "react";
import { Pagination, Spin, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { handleActiveTab } from "../../actions/header/headerActions";
import {
	handleCompleteEarningYourStudPage,
	handleHideCompleteEarningYourStudPage,
	handleViewEditChooseThisMounting,
	handleViewCompletethisMounting,
} from "../../actions/chooseYourEarning/chooseYourEarningActions";
import { useDispatch, useSelector } from "react-redux";
import ChooseSettingServices from "../../services/choose-settings.service";
import SelectYourDiamondService from "../../services/select-your-diamond.service";
import colorPanelService from "../../services/color-panel.service";
import styled from "styled-components";
import { setGlobalFontFamily } from "../../actions/PreviewColor/previewColor";
import { useTranslation } from "react-i18next";

const ChooseYourSetting = ({}) => {
	const { t } = useTranslation();
	const { Search } = Input;
	const dispatch = useDispatch();
	const [viewItem, setViewItem] = useState("grid");
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [TotalRowCount, setTotalRowCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [productMountList, setProductMountList] = useState([]);
	const [productList, setProductList] = useState({
		dealerId: "",
		totalCaratval: "",
		shape: "",
		skuValue: "",
		collectionSearch: "",
	});

	const [dealerID, setDealerID] = useState(0);
	/* Color Individually Start */

	const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
	const [isLightButtonColor, setIsLightButtonColor] = useState(false);
	const [isLightHoverColor, setIsLightHoverColor] = useState(false);

	const previewcolor = useSelector((state) => state.previewColorReducer);
	const [varColorObj, setVarColorObj] = useState({
		callToActionColor: "",
		hoverColor: "",
		columnHeaderAccentColor: "",
		linkColor: "",
	});
	// Options

	const initialOptionsData = {
		studbuilderLinkURL: "",
		internalUseLink: true,
		showOnlyDiamondSearch: true,
		showDealerDiamondsSKU: true,
		showScheduleViewing: true,
		showAddress: true,
		showCallForPrice: false,
		applyGstTax: true,
		gstTaxValue: "",
		showPinterestShare: true,
		showTwitterShare: true,
		showFacebookShare: true,
		showFacebookLike: true,
		showAddToCartButton: true,
		ShoppingCartURL: "",
		showViewCartButton: true,
		ShowInstagramPlus: true,
	};
	const [myOptionsData, setMyOptionsData] = useState(initialOptionsData);
	const [showAllContent, setShowAllContent] = useState(true);

	/* Color Individually Ends */

	const search = useLocation().search;
	const paramdealerId = new URLSearchParams(search).get("DealerID");
	const diamondId = new URLSearchParams(search).get("diamondId");
	const paramDetailLinkURL = new URLSearchParams(search).get(
		"paramDetailLinkURL"
	);
	const paramCut = new URLSearchParams(search).get("paramCut");
	const paramSize = new URLSearchParams(search).get("size");
	const paramdealerId1 = new URLSearchParams(search).get("dealerID");
	const pairId = new URLSearchParams(search).get("pairId");
	const totalPrice = new URLSearchParams(search).get("totalPrice");

	const handleGetstudBuilderOptiondetails = (id) => {
		let inputData = {
			dealerID: id,
		};

		try {
			SelectYourDiamondService.GetstudBuilderOptiondetails(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData[0];
					if (message == "Success") {
						if (responseData) {
							if (response.data.responseData.length > 0) {
								setMyOptionsData((prevState) => ({
									...prevState,
									studbuilderLinkURL: responseData.studBuilderURL,
									internalUseLink: responseData.internalLinkSB,
									showOnlyDiamondSearch: responseData.onlyDiamondSearch,
									showDealerDiamondsSKU: responseData.stud_ShowSKU,
									showScheduleViewing: responseData.showScheduleView,
									showAddress: responseData.showAddresses,
									showCallForPrice: responseData.callForPricestud,
									applyGstTax: responseData.showGstPrice,
									gstTaxValue: responseData.taxRate,
									showPinterestShare: responseData.showPinterestShare,
									showTwitterShare: responseData.showTwitterShare,
									showFacebookShare: responseData.showFacebookShare,
									showFacebookLike: responseData.showFacebookLike,
									showAddToCartButton: responseData.showAddToCartButtonStud,
									ShoppingCartURL: responseData.diamondstudshoppingurl,
									showViewCartButton: responseData.showViewCartButtonStud,
									ShowInstagramPlus: responseData.showInstagramShare,
								}));
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
					setMyOptionsData((prevState) => ({
						showOnlyDiamondSearch: false,
					}));
				});
		} catch (error) {
			console.log(error);
			setMyOptionsData((prevState) => ({
				showOnlyDiamondSearch: false,
			}));
		}
	};

	useLayoutEffect(() => {
		if (paramdealerId) {
			if (paramdealerId) {
				handleGetColorsData(paramdealerId);
			}
		}
	}, [previewcolor.toogle]);
	const handleGetColorsData = async (id) => {
		let inputData = {
			dealerID: Number(id),
			moduleName: "StudBuilder",
			hoverEffect: "",
			columnHeaderAccent: "",
			linkColor: "",
			actionButton: "",
		};
		try {
			await colorPanelService
				.GetHoverEffect(inputData)
				.then((response) => {
					let msg = response.data.message;
					let initialColorObj =
						response.data.responseData.initialHoverEffectList[0];
					let newColorObj =
						response.data.responseData.ringBuilder_HoverEffect_List[0];
					let colorObj = {
						hoverEffect: "",
						columnHeaderAccent: "",
						linkColor: "",
						actionButton: "",
					};

					if (msg == "Success") {
						if (initialColorObj) {
							let tempobj = colorObj;
							tempobj.hoverEffect = newColorObj
								? newColorObj.hoverEffect == ""
									? initialColorObj.hoverEffect
									: newColorObj.hoverEffect
								: initialColorObj.hoverEffect;
							tempobj.columnHeaderAccent = newColorObj
								? newColorObj.columnHeaderAccent == ""
									? initialColorObj.columnHeaderAccent
									: newColorObj.columnHeaderAccent
								: initialColorObj.columnHeaderAccent;
							tempobj.linkColor = newColorObj
								? newColorObj.linkColor == ""
									? initialColorObj.linkColor
									: newColorObj.linkColor
								: initialColorObj.linkColor;
							tempobj.actionButton = newColorObj
								? newColorObj.actionButton == ""
									? initialColorObj.actionButton
									: newColorObj.actionButton
								: initialColorObj.actionButton;

							dispatch(setGlobalFontFamily(newColorObj.chooseFont));

							if (tempobj) {
								setVarColorObj({
									...varColorObj,
									callToActionColor: tempobj.actionButton,
									hoverColor: tempobj.hoverEffect,
									columnHeaderAccentColor: tempobj.columnHeaderAccent,
									linkColor: tempobj.linkColor,
								});
								lightOrDark(tempobj.columnHeaderAccent, "header");
								lightOrDark(tempobj.callToActionColor, "button");
								lightOrDark(tempobj.hoverColor, "hover");
							} else {
								setVarColorObj({
									...varColorObj,
									callToActionColor: initialColorObj.actionButton,
									hoverColor: initialColorObj.hoverEffect,
									columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
									linkColor: initialColorObj.linkColor,
								});
								lightOrDark(initialColorObj.columnHeaderAccent, "header");
								lightOrDark(initialColorObj.callToActionColor, "button");
								lightOrDark(initialColorObj.hoverColor, "hover");
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	function lightOrDark(color, type) {
		// Variables for red, green, blue values
		var r, g, b, hsp;

		if (color) {
			// Check the format of the color, HEX or RGB?
			if (color.match(/^rgb/)) {
				// If RGB --> store the red, green, blue values in separate variables
				color = color.match(
					/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
				);

				r = color[1];
				g = color[2];
				b = color[3];
			} else {
				// If hex --> Convert it to RGB: http://gist.github.com/983661
				color = +(
					"0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
				);

				r = color >> 16;
				g = (color >> 8) & 255;
				b = color & 255;
			}

			// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
			hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

			// Using the HSP value, determine whether the color is light or dark
			// if hsp> 127.5 then it is dark
			if (hsp > 127.5) {
				if (type == "header") {
					setIsLightHeaderColor(true);
				} else if (type == "button") {
					setIsLightButtonColor(true);
				} else {
					setIsLightHoverColor(true);
				}
			} else {
				if (type == "header") {
					setIsLightHeaderColor(false);
				} else if (type == "button") {
					setIsLightButtonColor(false);
				} else {
					setIsLightHoverColor(false);
				}
			}
		}
	}

	const Wrapper = styled.div`
		& .whole__shapes .shapes__block .image:hover {
			border: 1px solid ${varColorObj.hoverColor};
		}

		& .whole__shapes .shapes__block:hover .image {
			border: 1px solid ${varColorObj.hoverColor};
		}

		& .whole__shapes .shapes__block .image .selected {
			border: 1px solid ${varColorObj.linkColor};
		}

		& .whole__shapes .shapes__block.selected .image {
			border: 1px solid ${varColorObj.linkColor};
		}

		& .img__block .imgselct__div:hover {
			border-bottom: 2px solid ${varColorObj.hoverColor};
		}

		& .img__block .imgselct__div.selected {
			border-bottom: 2px solid ${varColorObj.linkColor};
		}

		& .selection__block .selection__box:hover {
			border: 1px solid ${varColorObj.hoverColor};
			background-color: ${varColorObj.hoverColor};
		}
		& .selection__block .selection__box.selected {
			border: 1px solid ${varColorObj.linkColor};
			background-color: ${varColorObj.linkColor};
		}

		& .ant-table-thead > tr > th {
			background: ${varColorObj.columnHeaderAccentColor} !important;
			height: 50px;
			color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
			line-height: 14px;
		}

		& .ant-btn-primary {
			background: ${varColorObj.callToActionColor} !important;
			border-color: ${varColorObj.callToActionColor} !important;
		}
		& .ant-btn-primary:hover {
			background: ${varColorObj.hoverColor} !important;
			border-color: ${varColorObj.hoverColor} !important;
		}

		& .primary-btn {
			background: ${varColorObj.callToActionColor} !important;
			border-radius: 5px !important;
			color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}

		& .primary-btn:hover {
			background: ${varColorObj.hoverColor} !important;
			border-radius: 5px !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}

		& .ant-btn {
			background: ${varColorObj.callToActionColor} !important;
			border-radius: 5px !important;
			color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}

		& .ant-btn:hover {
			background: ${varColorObj.hoverColor} !important;
			border-radius: 5px !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}
		& .ant-input-search-button {
			background: #fff !important;
			border: 1px solid #d9d9d9;
			border-radius: 0 !important;
			height: 32px;
			border-left: 0;
		}

		& .ant-input-search-button:hover {
			background: #fff !important;
			border: 1px solid #d9d9d9;
			border-radius: 0 !important;
			height: 32px;
			border-left: 0;
		}
		& .linkText {
			color: ${varColorObj.linkColor};
			text-decoration: none;
			cursor: pointer;
		}

		& .linkText:hover {
			color: ${varColorObj.hoverColor};
		}

		& .iframe__block .itemdetails__btns p:hover img {
			filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
				brightness(93%) contrast(89%);
			color: ${varColorObj.hoverColor};
		}

		& .iframe__block .itemdetails__btns p:hover span {
			color: ${varColorObj.hoverColor};
		}

		& .ant-pagination-item-active {
			border: 1px solid ${varColorObj.linkColor} !important;
		}

		& .ant-pagination-item:hover {
			border: 1px solid ${varColorObj.hoverColor};
		}
		& .nav-tabs .nav-link.active {
			background: ${varColorObj.columnHeaderAccentColor} !important;
			color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .list_icon.active {
			border: 1px solid ${varColorObj.callToActionColor};
			background: ${varColorObj.callToActionColor} !important;
			color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .grid_icon.active {
			border: 1px solid ${varColorObj.callToActionColor};
			background: ${varColorObj.callToActionColor} !important;
			color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .grid_icon:hover {
			border: 1px solid ${varColorObj.hoverColor};
			background: ${varColorObj.hoverColor} !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .list_icon:hover {
			border: 1px solid ${varColorObj.hoverColor};
			background: ${varColorObj.hoverColor} !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .ant-input-search-button {
			color: rgba(0, 0, 0, 0.45) !important;
		}
		& .ant-input-search-button:hover {
			color: rgba(0, 0, 0, 0.45) !important;
		}
	`;

	useEffect(() => {
		if (paramdealerId) {
			if (paramdealerId) {
				dispatch(handleHideCompleteEarningYourStudPage());
				handleGetstudBuilderOptiondetails(paramdealerId);
				setDealerID(paramdealerId);
				GetAllStudMountList(
					paramdealerId,
					"52",
					pageSize,
					pageNo,
					"",
					paramSize,
					"",
					"",
					"",
					"",
					""
				);
				setProductList((prevState) => ({
					...prevState,
					dealerId: paramdealerId1,
					totalCaratval: paramSize,
					shape: paramCut,
				}));
			}
		}
	}, []);
	const handleViewChange = (viewname) => {
		setViewItem(viewname);
	};

	const navigate = useNavigate();
	const handleChooseMounting = (details) => {
		let obj = details;

		navigate(
			"/completeYourRing" +
				`?isSocialPage=True&DealerID=${paramdealerId}&dealerID1=${obj.dealerID}&dealerID=${paramdealerId1}&paramDetailLinkURL=${paramDetailLinkURL}&diamondId=${diamondId}&pageNo=1&isAllowed=True&paramCut=${paramCut}&size=${paramSize}&pairId=${pairId}&totalPrice=${totalPrice}&gfInventoryID=${obj.gfInventoryID}&styleNumber=${obj.styleNumber}&isTab=false&tabName=completeyourring&mountEdit=True&diamondViewEdit=True`
		);

		dispatch(handleCompleteEarningYourStudPage(obj));
		dispatch(handleViewEditChooseThisMounting());
		dispatch(handleViewCompletethisMounting());
		dispatch(handleActiveTab("completeYourRing"));
	};
	function onChangePage(page, pageSize) {
		if (page !== pageNo) {
			setPageNo(page);
			GetAllStudMountList(
				dealerID,
				"52",
				pageSize,
				page,
				"",
				productList.totalCaratval,
				"",
				productList.skuValue,
				productList.collectionSearch,
				"",
				""
			);
		}
	}
	function onShowSizeChange(current, pageNewSize) {
		if (pageNewSize !== pageSize) {
			setPageSize(pageNewSize);
			GetAllStudMountList(
				dealerID,
				"52",
				pageNewSize,
				current,
				"",
				productList.totalCaratval,
				"",
				productList.skuValue,
				productList.collectionSearch,
				"",
				""
			);
		}
	}
	const handleSearchCollection = (value) => {
		setProductList((prevState) => ({
			...prevState,
			collectionSearch: value,
		}));
		GetAllStudMountList(
			dealerID,
			"52",
			pageSize,
			pageNo,
			"",
			productList.totalCaratval,
			"",
			productList.skuValue,
			value,
			"",
			""
		);
	};

	const GetAllStudMountList = (
		dealerId,
		categoryId,
		pageSize,
		pageNo,
		order,
		totalCaratval,
		shape,
		skuValue,
		collectionSearch,
		tax,
		ccode
	) => {
		let inputData = {
			dealerID: dealerId,
			jewelryCategory: categoryId,
			pageSize: pageSize.toString(),
			currentPage: pageNo.toString(),
			orderBy: order,
			totalCarat: totalCaratval,
			shape: shape != "emerald" ? shape : "",
			skuValue: skuValue,
			collectionSearch: collectionSearch,
			countryTaxRate: tax,
			countryCode: ccode,
		};
		setLoading(true);
		try {
			ChooseSettingServices.GetAllStudMount(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData;
					if (message == "Success") {
						if (responseData.studMountProductsLst) {
							if (responseData.studMountProductsLst.length > 0) {
								setProductMountList(responseData.studMountProductsLst);
								setTotalRowCount(
									responseData.studMountProductsLst[0].intTotalRecords
								);
								setLoading(false);
							}
						} else {
							setProductMountList([]);
							setLoading(false);
							setTotalRowCount(0);
						}
					}
					setShowAllContent(false);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					setShowAllContent(false);
				});
		} catch (error) {
			console.log(error);
			setLoading(false);
			setShowAllContent(false);
		}
	};

	return (
		<Spin spinning={showAllContent} style={{ minHeight: "50vh" }}>
			{showAllContent == false && (
				<React.Fragment>
					<Wrapper>
						<div className="allprod_section jwlsrch__section app__preview choose__setting p-0">
							<div className="row">
								<div className="col-md-12">
									<div className="filter_section">
										<div className="col-md-12 desktop__version">
											<div className="filt_optndiv mb-4">
												<div className="gview__div">
													<span className="font__bold mr-2 similar__text">
														{TotalRowCount} {t("Mounting style Found")}
													</span>
													<span
														className={
															viewItem === "grid"
																? "fa fa-th grid_icon active"
																: "fa fa-th grid_icon"
														}
														onClick={() => handleViewChange("grid")}></span>
													<span
														className={
															viewItem === "list"
																? "fa fa-th-list list_icon active"
																: "fa fa-th-list list_icon"
														}
														onClick={() => handleViewChange("list")}></span>
												</div>
												<div>
													<Search
														placeholder={t("Search Mounting #")}
														onSearch={(e) => handleSearchCollection(e)}
														allowClear
														style={{ width: 250 }}
													/>
												</div>
											</div>
										</div>

										<div className="col-md-12 mobile__version">
											<div className="filt_optndiv">
												<div className="gview__div">
													<div className="row">
														<div className="col-6 text-left-mobile">
															<span className="font__bold mr-2 similar__text">
																{TotalRowCount} {t("Mounting style Found")}
															</span>
														</div>
														<div className="col-6 text-right-mobile">
															<span
																className={
																	viewItem === "grid"
																		? "fa fa-th grid_icon active mb-3"
																		: "fa fa-th grid_icon"
																}
																onClick={() => handleViewChange("grid")}></span>
															<span
																className={
																	viewItem === "list"
																		? "fa fa-th-list list_icon active"
																		: "fa fa-th-list list_icon mb-3"
																}
																onClick={() => handleViewChange("list")}></span>
														</div>
														<div className="col-12">
															<div>
																<Search
																	placeholder={t("Search Mounting #")}
																	onSearch={(e) => handleSearchCollection(e)}
																	allowClear
																	style={{ width: 250 }}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										{viewItem === "grid" && (
											<div className="col-md-12">
												<div className="prod__section">
													<Spin spinning={loading}>
														<div className="row">
															{productMountList &&
																productMountList.map((item, i) => {
																	return (
																		<div className="col-lg-3 col-md-6 mb-4">
																			<div className="prod__maindiv">
																				<div className="prodimg__div">
																					<img src={item.imagePath} alt="" />
																				</div>
																				<div className="details__div">
																					<span className="style__txt mb-2">
																						{item.productName}
																					</span>
																					<span className="price__txt mb-2">
																						{myOptionsData.showCallForPrice ==
																						true ? (
																							<span>{t("Call For Price")}</span>
																						) : (
																							<>
																								{item.finalPrice == "0" ? (
																									t("Call For Price")
																								) : (
																									<span>
																										{item?.currencycode ?? ""}{" "}
																										{item.currencySign}
																										{parseInt(item.finalPrice)
																											.toFixed()
																											.replace(
																												/\B(?=(\d{3})+(?!\d))/g,
																												","
																											)}
																									</span>
																								)}
																							</>
																						)}
																					</span>
																					<button
																						className="primary-btn"
																						onClick={() =>
																							handleChooseMounting(item)
																						}>
																						{t("Choose This Mounting")}
																					</button>
																				</div>
																			</div>
																		</div>
																	);
																})}
														</div>
														<div className="d-flex justify-content-between mt-3 align-items-center">
															<p
																className="mb-0"
																style={{ opacity: TotalRowCount > 0 ? 1 : 0 }}>
																<span>{TotalRowCount}</span> {t("of")}{" "}
																{TotalRowCount} {t("Mounting Style")}
															</p>
															<div>
																<Pagination
																	current={pageNo}
																	pageSize={pageSize}
																	total={TotalRowCount}
																	onChange={onChangePage}
																	onShowSizeChange={onShowSizeChange}
																	showSizeChanger="true"
																/>
															</div>
														</div>
													</Spin>
												</div>
											</div>
										)}

										{viewItem === "list" && (
											<div className="col-md-12 mt-4">
												<Spin spinning={loading}>
													<div className="prod__section list__view">
														{productMountList &&
															productMountList.map((item, i) => {
																return (
																	<div className="row m-0">
																		<div className="col-lg-12 mb-3">
																			<div className="prod__maindiv row flex-row align-items-center">
																				<div className="col-4 col-lg-3">
																					<div className="prodimg__div">
																						<img src={item.imagePath} alt="" />
																					</div>
																				</div>
																				<div className="col-4 col-lg-3">
																					<div className="details__div">
																						<span className="style__txt">
																							{item.productName}
																						</span>
																					</div>
																				</div>
																				<div className="col-4 col-lg-3">
																					<div className="details__div">
																						{myOptionsData.showCallForPrice ? (
																							<span>{t("Call For Price")}</span>
																						) : item.finalPrice == "0" ? (
																							t("Call For Price")
																						) : (
																							<span className="price__txt">
																								{item?.currencycode ?? ""}
																								{item.currencySign}
																								{parseInt(item.finalPrice)
																									.toFixed()
																									.replace(
																										/\B(?=(\d{3})+(?!\d))/g,
																										","
																									)}
																							</span>
																						)}
																					</div>
																				</div>
																				<div className="col-12 col-lg-3 text-right">
																					<button
																						className="primary-btn mobile-w-100"
																						onClick={() =>
																							handleChooseMounting(item)
																						}>
																						{t("Choose This Mounting")}
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																);
															})}
														<div className="d-flex justify-content-between mt-3 align-items-center">
															<p className="mb-0">
																<span>1 - {TotalRowCount}</span> of{" "}
																{TotalRowCount} {t("Mounting Style")}
															</p>
															<div>
																<Pagination
																	current={pageNo}
																	pageSize={pageSize}
																	total={TotalRowCount}
																	onChange={onChangePage}
																	onShowSizeChange={onShowSizeChange}
																	showSizeChanger="true"
																/>
															</div>
														</div>
													</div>
												</Spin>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</Wrapper>
				</React.Fragment>
			)}
		</Spin>
	);
};

export default ChooseYourSetting;
