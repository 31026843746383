import React, { useEffect, useState } from "react";
import selectYourDiamondService from "../services/select-your-diamond.service";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Footer = () => {
	const { t } = useTranslation();
	const search = useLocation().search;
	const paramDealerId = new URLSearchParams(search).get("DealerLink");
	const DealerID = new URLSearchParams(search).get("DealerID");
	const [visible, setVisible] = useState(false);
	const [whiteLableName, setWhiteLableName] = useState("");
	const [labelLink, setLabelLink] = useState("");
	useEffect(() => {
		let id = paramDealerId ?? DealerID;
		handleGetMenuList(id);
		handleGetWhiteLabel();
	}, []);

	const handleGetMenuList = (Id) => {
		try {
			let inputData = {
				dealerId: Id,
			};

			selectYourDiamondService
				.GetMenuList(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData;

					if (message == "Success") {
						setVisible(responseData.diamondSearchMenu.chkSBSPBy);
					} else {
						setVisible(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetWhiteLabel = () => {
		try {
			selectYourDiamondService
				.CompanyWhileLabel()
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData;
					if (message == "Success") {
						if (responseData) {
							setWhiteLableName(responseData[0].name);
							setLabelLink(responseData[0].footerLogoLink);
						} else {
							setWhiteLableName("");
							setLabelLink("");
						}
					} else {
						setWhiteLableName("");
						setLabelLink("");
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className="powered__by">
			{visible && (
				<a href={labelLink} target="_blank">
					{t("Powered By")} {whiteLableName ?? ""}
				</a>
			)}
		</div>
	);
};

export default Footer;
