import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "../locales/en/translation.json";
import esTranslation from "../locales/es/translation.json";
import arTranslation from "../locales/ar/translation.json";

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: enTranslation, //// English
		},
		es: {
			translation: esTranslation, //// Spanish
		},
		ar: {
			translation: arTranslation, //// Arabic
		},
	},
	lng: "en",
	fallbackLng: "en",
	debug: true,
	interpolation: {
		escapeValue: false,
	},
	react: {
		useSuspense: true,
	},
});

export default i18n;
