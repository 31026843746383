import React, { useEffect, useState, useLayoutEffect } from "react";
import { Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import ResetIcon from "../../assets/images/choose-setting/reset__icon.svg";
import selectYourDiamondService from "../../services/select-your-diamond.service";
import { useSelector, useDispatch } from "react-redux";
import {
	handleResetMinedPage,
	handleResetLabPage,
	handleResetFancyPage,
	handleResetRequestPage,
	handleSetSaveSearchMinedTab,
	handleSetSwitchFilter,
	handleSetSaveSearchLabTab,
	handleSetSwitchLabFilter,
	handleSetSaveSearchFancyTab,
	handleSetSwitchFancyFilter,
	handleSetSwitchRequestFilter,
	handleSetSaveSearchRequestTab,
} from "../../actions/selectYourDiamond/selectYourDiamond";
import { handleHideCompleteEarningYourStudPage } from "../../actions/chooseYourEarning/chooseYourEarningActions";
import { NotificationManager } from "react-notifications";
import colorPanelService from "../../services/color-panel.service";
import { setGlobalFontFamily } from "../../actions/PreviewColor/previewColor";
import { useTranslation } from "react-i18next";

const SelectYourDiamond = ({ props }) => {
	const { t } = useTranslation();
	const CompareIds = useSelector(
		(state) => state.selectYourDiamondReducer.compareIds
	);
	const navigate = useNavigate();
	const [key, setKey] = useState("Standard");
	const [navigationData, setNavigationData] = useState([]);
	const [orderNumbers, setOrderNumbers] = useState([]);
	const dispatch = useDispatch();
	const search = useLocation().search;
	const dealerId = new URLSearchParams(search).get("DealerLink");
	const isChange = new URLSearchParams(search).get("isChange");
	const tabkey = new URLSearchParams(search).get("tabkey");
	const paramdealerId = new URLSearchParams(search).get("DealerLink");
	const isTab = new URLSearchParams(search).get("isTab");

	/* Color Individually Start */
	const [wrapperLoading, setWrapperLoading] = useState(false);
	const previewcolor = useSelector((state) => state.previewColorReducer);
	const [varColorObj, setVarColorObj] = useState({
		callToActionColor: "",
		hoverColor: "",
		columnHeaderAccentColor: "",
		linkColor: "",
	});
	useLayoutEffect(() => {
		if (paramdealerId) {
			handleGetColorsData(paramdealerId);
			handleGetNavigationData(paramdealerId);
		}
	}, [previewcolor.toogle]);

	const handleGetColorsData = async (id) => {
		let inputData = {
			dealerID: Number(id),
			moduleName: "StudBuilder",
			hoverEffect: "",
			columnHeaderAccent: "",
			linkColor: "",
			actionButton: "",
		};
		setWrapperLoading(true);
		try {
			await colorPanelService
				.GetHoverEffect(inputData)
				.then((response) => {
					let msg = response.data.message;
					let initialColorObj =
						response.data.responseData.initialHoverEffectList[0];
					let newColorObj =
						response.data.responseData.ringBuilder_HoverEffect_List[0];
					let colorObj = {
						hoverEffect: "",
						columnHeaderAccent: "",
						linkColor: "",
						actionButton: "",
					};

					if (msg == "Success") {
						if (initialColorObj) {
							let tempobj = colorObj;
							tempobj.hoverEffect = newColorObj
								? newColorObj.hoverEffect == ""
									? initialColorObj.hoverEffect
									: newColorObj.hoverEffect
								: initialColorObj.hoverEffect;
							tempobj.columnHeaderAccent = newColorObj
								? newColorObj.columnHeaderAccent == ""
									? initialColorObj.columnHeaderAccent
									: newColorObj.columnHeaderAccent
								: initialColorObj.columnHeaderAccent;
							tempobj.linkColor = newColorObj
								? newColorObj.linkColor == ""
									? initialColorObj.linkColor
									: newColorObj.linkColor
								: initialColorObj.linkColor;
							tempobj.actionButton = newColorObj
								? newColorObj.actionButton == ""
									? initialColorObj.actionButton
									: newColorObj.actionButton
								: initialColorObj.actionButton;

							dispatch(setGlobalFontFamily(newColorObj.chooseFont));

							if (tempobj) {
								setVarColorObj({
									...varColorObj,
									callToActionColor: tempobj.actionButton,
									hoverColor: tempobj.hoverEffect,
									columnHeaderAccentColor: tempobj.columnHeaderAccent,
									linkColor: tempobj.linkColor,
								});
							} else {
								setVarColorObj({
									...varColorObj,
									callToActionColor: initialColorObj.actionButton,
									hoverColor: initialColorObj.hoverEffect,
									columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
									linkColor: initialColorObj.linkColor,
								});
							}
						}
					}
					setWrapperLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setWrapperLoading(false);
				});
		} catch (error) {
			console.log(error);
			setWrapperLoading(false);
		}
	};

	const handlTabChanges = (val) => {
		setKey(val);

		if (val == "Mined") {
			navigate(
				"/Mined" +
					`?DealerLink=${dealerId}&tabName=selectYourDiamond&tabkey=Standard`
			);
		} else if (val == "Lab Grown") {
			navigate("/Lab" + `?DealerLink=${dealerId}&tabName=selectYourDiamond`);
		} else if (val == "Request") {
			navigate(
				"/Request" +
					`?DealerLink=${dealerId}&tabName=selectYourDiamond&tabkey=Request`
			);
		} else if (val == "Compare") {
			let compareIds = CompareIds.toString();
			navigate("/Compare" + `?DealerLink=${dealerId}&CompareIds=${compareIds}`);
		} else {
			navigate(
				"/Mined" +
					`?DealerLink=${dealerId}&tabName=selectYourDiamond&tabkey=Standard`
			);
		}
	};

	useEffect(() => {
		if (paramdealerId) {
			handleGetNavigationData(paramdealerId);
			dispatch(handleHideCompleteEarningYourStudPage());
			if (tabkey) setKey(tabkey);
		}
	}, []);

	const handleGetNavigationData = async (id) => {
		let inputData = {
			dealerId: id.toString(),
		};

		try {
			await selectYourDiamondService
				.GetStudBuilderOpeningPage(inputData)
				.then((response) => {
					let responseData = response.data.responseData;
					if (responseData) {
						let navigationArray = [];
						if (responseData._LabData) {
							navigationArray.push(responseData._LabData[0]);
						}
						if (responseData._MinedData) {
							navigationArray.push(responseData._MinedData[0]);
						}
						if (responseData._RequestDiamondData) {
							navigationArray.push(responseData._RequestDiamondData[0]);
						}
						if (responseData._CompareDiamondData) {
							navigationArray.push(responseData._CompareDiamondData[0]);
						}
						let newArray = [];
						for (let i = 0; i < navigationArray.length + 1; i++) {
							newArray.push((i + 1).toString());
						}
						setOrderNumbers(newArray);

						let newminArr = navigationArray
							.filter((x) => x.setting == "True")
							.map((x) => {
								return x.order;
							});
						let val = Math.min(...newminArr);
						let newActiveTab = navigationArray
							.filter((item) => {
								return item.setting == "True" && item.order == val.toString();
							})[0]
							.name.toString();
						if (isChange == "True") {
							if (newActiveTab == "Mined") {
								navigate(
									"/Mined" +
										`?DealerLink=${id}&isTab=True&tabName=selectYourDiamond&tabkey=Standard`
								);
							} else if (newActiveTab == "Request") {
								navigate(
									"/Request" +
										`?DealerLink=${id}&isTab=True&tabName=selectYourDiamond&tabkey=Request`
								);
							} else {
								navigate(
									"/Mined" +
										`?DealerLink=${id}&isTab=True&tabName=selectYourDiamond&tabkey=Standard`
								);
							}
						} else {
							setKey(tabkey);
						}

						setNavigationData(navigationArray);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	function getTextStyle(name) {
		if (tabkey) {
			if (tabkey == name) {
				return {
					color: "#fff",
					background: `${varColorObj.columnHeaderAccentColor}`,
				};
			}
		}
	}

	const handleResetForPage = () => {
		if (key == "Request") {
			dispatch(handleResetRequestPage());
			dispatch(handleSetSaveSearchRequestTab(false));
		} else {
			if (key == "Standard") {
				dispatch(handleResetMinedPage());
				dispatch(handleSetSaveSearchMinedTab(false));
			}
			if (key == "Lab") {
				dispatch(handleResetLabPage());
				dispatch(handleSetSaveSearchLabTab(false));
			}
			if (key == "FancyColor") {
				dispatch(handleResetFancyPage());
				dispatch(handleSetSaveSearchFancyTab(false));
			}
			if (key == "Request") {
				dispatch(handleResetRequestPage());
			}
		}
		NotificationManager.success(t("Search Reset Successfully."));
	};

	const handleSaveSearchForPage = () => {
		if (key == "Request") {
			dispatch(handleSetSaveSearchRequestTab(true));
			dispatch(handleSetSwitchRequestFilter());
		} else {
			if (key == "Standard") {
				dispatch(handleSetSaveSearchMinedTab(true));
				dispatch(handleSetSwitchFilter());
			}
			if (key == "Lab") {
				dispatch(handleSetSaveSearchLabTab(true));
				dispatch(handleSetSwitchLabFilter());
			}
			if (key == "") {
				dispatch(handleSetSaveSearchFancyTab(true));
				dispatch(handleSetSwitchFancyFilter());
			}
		}
		NotificationManager.success(t("Search Saved Successfully."));
	};

	return (
		<React.Fragment>
			{isTab != "false" ? (
				<div className="allprod_section jwlsrch__section app__preview">
					<Spin spinning={wrapperLoading}>
						<div className="row">
							<div className="col-md-12">
								<div className="prodetail__section">
									<div className="col-md-12">
										<div className="prod__maindiv app__preview p-0 border-0">
											<div className="row">
												<div className="col-lg-12 col-md-12">
													<div className="prodesc__div">
														<div className="tright__btns">
															{key == "Compare" ? null : (
																<p onClick={handleSaveSearchForPage}>
																	<img />
																	<span>{t("Save Search")}</span>
																</p>
															)}
															{key == "Compare" ? null : (
																<p onClick={handleResetForPage}>
																	<img src={ResetIcon} />
																	<span>{t("Reset")}</span>
																</p>
															)}
														</div>
														{console.log(orderNumbers, "**orderNumbers")}
														{console.log(navigationData, "**navigationData")}
														<div className="custom__tab">
															<ul>
																{orderNumbers.map((order) => {
																	return navigationData.map((tab) => {
																		return (
																			tab.setting == "True" &&
																			order == tab.order && (
																				<li
																					style={getTextStyle(tab.name)}
																					onClick={() =>
																						handlTabChanges(tab.name)
																					}
																					title={tab.name}>
																					<div className="mr-2 nav-btn">
																						{tab.name == "Compare"
																							? key == "Compare"
																								? t("Compare")
																								: t("Compare") +
																								  " " +
																								  t(
																										"(" +
																											CompareIds.length +
																											")"
																								  )
																							: tab.name == "Fancy Colored"
																							? t(
																									tab.name.split(/(?=[A-Z])/)[0]
																							  ) +
																							  " " +
																							  t(
																									tab.name.split(/(?=[A-Z])/)[1]
																							  )
																							: t(tab.name)}
																					</div>
																				</li>
																			)
																		);
																	});
																})}
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Spin>
				</div>
			) : null}
		</React.Fragment>
	);
};

export default SelectYourDiamond;
