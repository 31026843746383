import React, { useState, Fragment, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	handleColorButtonChange,
	handleSwitchChange,
	setGlobalFontFamily,
} from "../actions/PreviewColor/previewColor";

import { useLocation } from "react-router-dom";
import ColorPanelService from "../services/color-panel.service";
import {
	NotificationManager,
	NotificationContainer,
} from "react-notifications";
import { Button } from "antd";
import close_red from "../assets/images/icons/close_red.svg";
import { useTranslation } from "react-i18next";

const ColorPanel = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const search = useLocation().search;
	const [isChooseCss, setIsChooseCss] = useState(false);
	const [hoverColor, setHoverColor] = useState("");
	const [columnHeaderAccentColor, setColumnHeaderAccentColor] = useState("");
	const [fontValue, setFontValue] = useState("");
	const [linkColor, setLinkColor] = useState("");
	const [callToActionColor, setCallToActionColor] = useState("");
	const [varColorObj, setVarColorObj] = useState({
		callToActionColor: "",
		hoverColor: "",
		columnHeaderAccentColor: "",
		linkColor: "",
	});
	const [initialColorObj, setInitialColorObj] = useState({
		callToActionColor: "",
		hoverColor: "",
		columnHeaderAccentColor: "",
		linkColor: "",
	});
	const [toggle, setToggle] = useState(true);
	const [dealerId, setDealerId] = useState();
	const [showColor, setShowColor] = useState(false);

	const previewcolor = useSelector((state) => state.previewColorReducer);
	const loginDetails = useSelector((state) => state.loginDetailsReducer);
	const paramdealerId = new URLSearchParams(search).get("DealerLink");

	const fontStyle = useSelector(
		(state) => state.previewColorReducer.fontFamily
	);

	useEffect(() => {
		setFontValue(fontStyle);
	}, [fontStyle]);

	useLayoutEffect(() => {
		if (paramdealerId) {
			handleGetColorsData(paramdealerId);
			setDealerId(paramdealerId);
		}
	}, [paramdealerId]);

	useEffect(() => {
		if (loginDetails) {
			if (loginDetails.dealerId) {
				setDealerId(loginDetails.dealerId);
			}
		}
	}, [paramdealerId]);

	useEffect(() => {
		window.addEventListener("message", handleIframeTask);
	}, []);

	const handleIframeTask = (e) => {
		if (e.data === "Show") {
			setShowColor(true);
		}
	};

	useEffect(() => {
		if (previewcolor) {
			if (previewcolor.buttonColor) {
				setVarColorObj({
					...varColorObj,
					callToActionColor: previewcolor.buttonColor.callToActionColor,
					hoverColor: previewcolor.buttonColor.hoverColor,
					columnHeaderAccentColor:
						previewcolor.buttonColor.columnHeaderAccentColor,
					linkColor: previewcolor.buttonColor.linkColor,
				});
				setCallToActionColor(previewcolor.buttonColor.callToActionColor);
				setHoverColor(previewcolor.buttonColor.hoverColor);
				setLinkColor(previewcolor.buttonColor.linkColor);
				setColumnHeaderAccentColor(
					previewcolor.buttonColor.columnHeaderAccentColor
				);

				setToggle(previewcolor.toogle);
			}
		}
	}, [previewcolor.toogle, toggle]);

	useEffect(() => {
		if (isChooseCss == true) {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}
	}, [isChooseCss]);

	const handleColorDisChange = (type) => {
		handleAddUpdateColorsData(type);
	};

	const handleChooseCss = () => {
		setIsChooseCss(!isChooseCss);
	};

	const handleColorChange = (e, name) => {
		if (name == "hover") {
			setHoverColor(e.target.value);
		}
		if (name == "columnHeader") {
			setColumnHeaderAccentColor(e.target.value);
		}
		if (name == "linkColor") {
			setLinkColor(e.target.value);
		}
		if (name == "ctaColor") {
			setCallToActionColor(e.target.value);
		}
	};

	const onFontChange = (e) => {
		setFontValue(e.target.value);
	};
	// need to add the dispatch method for the needed color change
	const handleGetColorsData = async (id) => {
		let inputData = {
			dealerID: Number(id),
			moduleName: "StudBuilder",
			hoverEffect: "",
			columnHeaderAccent: "",
			linkColor: "",
			actionButton: "",
		};
		try {
			await ColorPanelService.GetHoverEffect(inputData)
				.then((response) => {
					let msg = response.data.message;
					let initialColorObj =
						response.data.responseData.initialHoverEffectList[0];
					let newColorObj =
						response.data.responseData.ringBuilder_HoverEffect_List[0];
					let colorObj = {
						hoverEffect: "",
						columnHeaderAccent: "",
						linkColor: "",
						actionButton: "",
					};

					if (msg == "Success") {
						if (initialColorObj) {
							setInitialColorObj({
								...initialColorObj,
								callToActionColor: initialColorObj.actionButton,
								hoverColor: initialColorObj.hoverEffect,
								columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
								linkColor: initialColorObj.linkColor,
							});
							let tempobj = colorObj;
							tempobj.hoverEffect = newColorObj
								? newColorObj.hoverEffect == ""
									? initialColorObj.hoverEffect
									: newColorObj.hoverEffect
								: initialColorObj.hoverEffect;
							tempobj.columnHeaderAccent = newColorObj
								? newColorObj.columnHeaderAccent == ""
									? initialColorObj.columnHeaderAccent
									: newColorObj.columnHeaderAccent
								: initialColorObj.columnHeaderAccent;
							tempobj.linkColor = newColorObj
								? newColorObj.linkColor == ""
									? initialColorObj.linkColor
									: newColorObj.linkColor
								: initialColorObj.linkColor;
							tempobj.actionButton = newColorObj
								? newColorObj.actionButton == ""
									? initialColorObj.actionButton
									: newColorObj.actionButton
								: initialColorObj.actionButton;

							dispatch(setGlobalFontFamily(newColorObj.chooseFont));

							if (tempobj) {
								dispatch(handleColorButtonChange(tempobj));
							} else {
								dispatch(handleColorButtonChange(initialColorObj));
							}
							dispatch(handleSwitchChange());
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleAddUpdateColorsData = async (type) => {
		let inputData;
		if (type == "Save") {
			inputData = {
				dealerID: Number(dealerId),
				moduleName: "StudBuilder",
				hoverEffect: hoverColor,
				columnHeaderAccent: columnHeaderAccentColor,
				linkColor: linkColor,
				actionButton: callToActionColor,
				chooseFont: fontValue,
			};
		} else {
			inputData = {
				dealerID: Number(dealerId),
				moduleName: "StudBuilder",
				hoverEffect: "",
				columnHeaderAccent: "",
				linkColor: "",
				actionButton: "",
				chooseFont: "LexendDeca-Regular",
			};
		}
		try {
			await ColorPanelService.AddUpdateHoverEffect(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						if (type == "Save") {
							NotificationManager.success(
								t("Color Panel Updated Successfully")
							);
							dispatch(setGlobalFontFamily(fontValue));
							handleGetColorsData(dealerId);
						} else {
							NotificationManager.success(
								t("Color panel has been reset successfully")
							);
							dispatch(setGlobalFontFamily("LexendDeca-Regular"));
							handleGetColorsData(dealerId);
						}
					} else {
						if (type == "Save") {
							NotificationManager.success(
								t("Color Panel not Updated Successfully")
							);
							handleGetColorsData(dealerId);
						} else {
							NotificationManager.success(
								t("Color panel has not been reset successfully")
							);
							handleGetColorsData(dealerId);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Fragment>
			<div
				className={
					isChooseCss
						? "slidebar__section__open slidebar__section"
						: "slidebar__section"
				}>
				<button
					onClick={handleChooseCss}
					className={
						showColor == true ? "primary-btn slidebar__btn m-0" : "display-none"
					}>
					<i className="fa fa-cog" aria-hidden="true"></i>
				</button>
				<div className="right__sidebar">
					<div className="font__block pb-0">
						<div className="row">
							<div className="col-6">
								<p className="font__bold">{t("Choose Font")} :</p>
							</div>
							<div className="col-6 text-right" onClick={handleChooseCss}>
								<img src={close_red} />
							</div>
						</div>
						<div className="font__options">
							<p>
								<span>{t("LexendDeca-Regular")}</span>
								<input
									type="radio"
									value="LexendDeca-Regular"
									checked={fontValue === "LexendDeca-Regular"}
									onChange={onFontChange}
								/>
							</p>
							<p>
								<span>{t("Times New Roman")}</span>
								<input
									type="radio"
									value="Times New Roman"
									checked={fontValue === "Times New Roman"}
									onChange={onFontChange}
								/>
							</p>
							<p>
								<span>{t("Cursive")}</span>
								<input
									type="radio"
									value="cursive"
									checked={fontValue === "cursive"}
									onChange={onFontChange}
								/>
							</p>
							<p>
								<span>{t("Monospace")}</span>
								<input
									type="radio"
									value="monospace"
									checked={fontValue === "monospace"}
									onChange={onFontChange}
								/>
							</p>
							<p>
								<span>{t("System UI")}</span>
								<input
									type="radio"
									value="system-ui"
									checked={fontValue === "system-ui"}
									onChange={onFontChange}
								/>
							</p>
						</div>
					</div>
					<hr />
					<div className="colorselection__block">
						<p className="font__bold">{t("Hover Effect")} : </p>
						<div className="color__section">
							<div className="left__block w-50">
								<input type="color" value="#000022" />
								<p>{initialColorObj.hoverColor}</p>
							</div>
							<div className="right__block w-50">
								<input
									type="color"
									value={hoverColor}
									onChange={(e) => handleColorChange(e, "hover")}
								/>
								<div className="input__block mb-0">
									<input
										type="text"
										onChange={(e) => handleColorChange(e, "hover")}
										value={hoverColor}
									/>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div className="colorselection__block">
						<p className="font__bold">{t("Column Header Accent")} : </p>
						<div className="color__section">
							<div className="left__block w-50">
								<input type="color" value="#000000" />
								<p>{initialColorObj.columnHeaderAccentColor}</p>
							</div>
							<div className="right__block w-50">
								<input
									type="color"
									value={columnHeaderAccentColor}
									onChange={(e) => handleColorChange(e, "columnHeader")}
								/>
								<div className="input__block mb-0">
									<input
										type="text"
										onChange={(e) => handleColorChange(e, "columnHeader")}
										value={columnHeaderAccentColor}
									/>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div className="colorselection__block">
						<p className="font__bold">{t("Link Color")} : </p>
						<div className="color__section">
							<div className="left__block w-50">
								<input type="color" value="#828282" />
								<p>{initialColorObj.linkColor}</p>
							</div>
							<div className="right__block w-50">
								<input
									type="color"
									value={linkColor}
									onChange={(e) => handleColorChange(e, "linkColor")}
								/>
								<div className="input__block mb-0">
									<input
										type="text"
										onChange={(e) => handleColorChange(e, "linkColor")}
										value={linkColor}
									/>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div className="colorselection__block">
						<p className="font__bold">{t("Call to Action Button")} : </p>
						<div className="color__section">
							<div className="left__block w-50">
								<input type="color" value="#000022" />
								<p>{initialColorObj.callToActionColor}</p>
							</div>
							<div className="right__block w-50">
								<input
									type="color"
									value={callToActionColor}
									onChange={(e) => handleColorChange(e, "ctaColor")}
								/>
								<div className="input__block mb-0">
									<input
										type="text"
										onChange={(e) => handleColorChange(e, "ctaColor")}
										value={callToActionColor}
									/>
								</div>
							</div>
						</div>
						<div className="sidebar__btn mt-4">
							<Button
								className="mr-2 primary-btn"
								onClick={() => handleColorDisChange("Save")}>
								{t("Save")}
							</Button>
							<Button
								className="primary-btn"
								onClick={() => handleColorDisChange("Reset")}>
								{t("Reset")}
							</Button>
						</div>
					</div>
					<hr />
				</div>
			</div>
			<NotificationContainer />
		</Fragment>
	);
};

export default ColorPanel;
