import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import ChooseYourSetting from "./component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "./component/ApplicationPreview/SelectYourDiamond";
import CompleteYourRing from "./component/ApplicationPreview/CompleteYourRing";
import Layout from "./component/Layout";
import MinedDiamond from "./component/common/SelectYourDiamond/MinedDiamond";
import Request from "./component/common/SelectYourDiamond/Request";
import ChooseSettingItemDetails from "./component/ApplicationPreview/ChooseSettingItemDetails";
import ColorPanel from "./component/ColorPanel";
import { useSelector } from "react-redux";
import i18n from "./i18n";
import { useCookies } from "react-cookie";

const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  const [cookies] = useCookies(["lngCookie"]);
  const fontStyle = useSelector(
    (state) => state.previewColorReducer.fontFamily
  );
  const language = cookies.lngCookie;

  useEffect(() => {
    if (language) {
      //// language check conditions
      let tempLanguage =
        language === "English" || language === "en"
          ? "en"
          : language === "Spanish" || language === "es"
          ? "es"
          : language === "Arabic" || language === "ar"
          ? "ar"
          : "en";
      i18n.changeLanguage(tempLanguage);
    }
  }, [language, i18n]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty(
      "--dynamic-font-family",
      fontStyle || "LexendDeca-Regular"
    );
  }, [fontStyle]);

  return (
    <div style={{ fontFamily: fontStyle ? fontStyle : "LexendDeca-Regular" }}>
      <React.Fragment>
        <BrowserRouter>
          <Routes>
            <Route path="/selectYourDiamond" element={<SelectYourDiamond />} />
            <Route exact path="/" element={<PrivateRoute />}>
              <Route path="/Mined" element={<MinedDiamond />} />
              <Route path="/Request" element={<Request />} />
              <Route
                path="/chooseYourSetting"
                element={<ChooseYourSetting />}
              />
              <Route path="/completeYourRing" element={<CompleteYourRing />} />
              <Route
                path="/viewDiamondDetails"
                element={<ChooseSettingItemDetails />}
              />
            </Route>
          </Routes>
          <ColorPanel></ColorPanel>
        </BrowserRouter>
      </React.Fragment>
    </div>
  );
};

export default App;
