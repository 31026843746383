import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.css";
import "../src/assets/css/style.css";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import { persistedStore, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { IntlProvider } from "react-intl";

const messages = {
	en: {
		greeting: "Gemfind",
	},
};

const locale = "en";

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistedStore}>
			<IntlProvider locale={locale} messages={messages[locale]}>
				<App />
			</IntlProvider>
		</PersistGate>
	</Provider>,

	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
